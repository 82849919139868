// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aroma-queen-tsx": () => import("./../../../src/pages/aroma-queen.tsx" /* webpackChunkName: "component---src-pages-aroma-queen-tsx" */),
  "component---src-pages-bella-aromatica-tsx": () => import("./../../../src/pages/bella-aromatica.tsx" /* webpackChunkName: "component---src-pages-bella-aromatica-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-coxcolumnar-tsx": () => import("./../../../src/pages/coxcolumnar.tsx" /* webpackChunkName: "component---src-pages-coxcolumnar-tsx" */),
  "component---src-pages-data-protection-tsx": () => import("./../../../src/pages/data-protection.tsx" /* webpackChunkName: "component---src-pages-data-protection-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mieze-nova-tsx": () => import("./../../../src/pages/mieze-nova.tsx" /* webpackChunkName: "component---src-pages-mieze-nova-tsx" */),
  "component---src-pages-mini-cox-tsx": () => import("./../../../src/pages/mini-cox.tsx" /* webpackChunkName: "component---src-pages-mini-cox-tsx" */)
}

